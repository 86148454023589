<style lang="scss"></style>
<!--eslint-disable-->
<template>
  <div class="activity-page">
    <b-card class="" title="PDF Forms">
      <b-card-text> This page shows all PDF forms.</b-card-text>
    </b-card>
    <b-card class="mb-0" no-body>
      <div class="row px-2 pt-1 justify-content-end mb-2">
        <section class="col-3">
          <label>Search</label>
          <b-input-group>
            <b-form-input
              v-model="filters.search"
              class="d-inline-block"
              placeholder="Search form title..."
              @keydown.enter="getMoreForms(1)"
            />
            <b-input-group-append>
              <b-button @click="getMoreForms(1)">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </section>

        <section class="col-3">
          <label>Resident ID</label>
          <b-input-group>
            <b-form-input
              v-model="filters.identifier"
              class="d-inline-block"
              placeholder="Search resident id..."
              @keydown.enter="getMoreForms(1)"
            />
            <b-input-group-append>
              <b-button @click="getMoreForms(1)">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </section>

        <section v-if="$can('ReadAll', 'PDFForm')" class="col-3">
          <label>Created by</label>
          <user-search-select
            :selected="selectedUser"
            @change="updateSelectedUser"
          />
        </section>

        <div class="ui-spacer"></div>

        <section class="col-3 d-flex justify-content-end align-items-end">
          <b-button @click="clearFilters()">Clear filters</b-button>
        </section>
      </div>
      <section>
        <b-table
          :current-page="currentPage"
          :fields="headers"
          :items="forms"
          :per-page="0"
          class="data-table"
        >
          <template #cell(created_at)="data">
            {{ data.item.created_at | formatDateTime }}
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              v-if="$can('Delete', 'PDFForm') || data.item.file"
              class="m-0"
              no-caret
              right
              toggle-class="text-decoration-none"
              variant="link"
            >
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item-button
                v-if="data.item.file"
                class="full-nested-btn"
                @click="viewPdf(data.item)"
              >
                View Form
              </b-dropdown-item-button>
              <b-dropdown-item-button
                v-if="$can('Delete', 'PDFForm')"
                class="full-nested-btn"
                @click="showDeleteModal(data.item)"
              >
                Delete Form
              </b-dropdown-item-button>
            </b-dropdown>
          </template>
        </b-table>
        <b-row>
          <b-col v-if="total_count > filters.page_size" class="my-1" md="6">
            <b-pagination
              v-model="currentPage"
              :per-page="filters.page_size"
              :total-rows="total_count"
              class="my-0"
              @change="getMoreForms"
            />
          </b-col>
          <p v-if="total_count === 0" class="col-6 ml-2 my-1">No records</p>
        </b-row>
      </section>
    </b-card>
  </div>
</template>
<!--eslint-enable-->

<script>
import PDFFormService from "@/services/PDFFormService";
import UserSearchSelect from "@/components/input/PaginatedSearchSelect/UserSearchSelect.vue";

export default {
  components: {
    UserSearchSelect,
  },
  props: {},
  data() {
    return {
      headers: [
        {
          key: "title",
          label: "Title",
        },
        {
          key: "identifier",
          label: "Resident ID",
        },
        {
          key: "created_at",
          label: "Created At",
        },
        {
          key: "user.name",
          label: "User",
        },
        {
          key: "actions",
          label: "",
          class: "text-right",
        },
      ],
      forms: [],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 15,
        search: "",
        identifier: "",
        user_uid: "",
      },
      selectedUser: null,
      total_count: 0,
      timeout: null,
    };
  },
  mounted() {
    this.getForms();
  },
  methods: {
    getMoreForms(page) {
      this.filters.page = page - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getForms();
    },
    async getForms() {
      try {
        const res = await PDFFormService.list(this.filters);
        this.forms = res.data.data;
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      } catch (err) {
        const res = err.response;
        let errorText = "Could not get PDF Forms, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      }
    },
    async viewPdf(form) {
      try {
        const res = await PDFFormService.getFile(form.id);
        const blob = new Blob([res.data]);
        const objectUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = objectUrl;
        link.download = form.file.filename;
        link.click();
      } catch (err) {
        const res = err.response;
        let errorText = "Could not get PDF, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      }
    },
    async deleteForm(form) {
      try {
        await PDFFormService.delete(form.id);
        this.$toast.success("PDF Form deleted successfully", {
          toastClassName: ["toast-std", "success-toast"],
        });
        if (
          this.total_count - 1 <=
          this.filters.page_size * this.filters.page
        ) {
          this.filters.page -= 1;
          this.currentPage -= 1;
        }
        await this.getForms();
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not delete PDF Form, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      }
    },
    showDeleteModal(form) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this form? This action cannot be undone.",
          {
            title: "Delete Form",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(value => {
          if (value) {
            this.deleteForm(form);
          }
        });
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 25,
        search: "",
        identifier: "",
      };
      this.selectedUser = null;

      this.getForms();
    },
    updateSelectedUser(user) {
      this.selectedUser = user;
      if (!this.selectedUser) {
        this.filters.user_uid = "";
      } else {
        this.filters.user_uid = user.uid;
      }
      this.getMoreForms(1)
    }
  },
};
</script>
